<script setup lang="ts">

import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useForm, useField } from 'vee-validate';
import * as yup from 'yup';
import { Notify } from "quasar";

import BaseButton from "../base/BaseButton.vue";
import BaseInput from "../base/BaseInput.vue";

import { Module, ProjectModuleSubscriptionConfigDto } from '@/store/module.interface';
import { useUserStore } from "@/store/userStore";
import { mailingListService } from "@/services/mailingList.service";
import { getTranslation } from "@/utils/data-display-utils";
import { constant } from "@/constant";

const { t } = useI18n();

// LIFECYCLE
onMounted(async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const code = urlSearchParams.get('code');
    if (code) {
        const successful = await mailingListService.verifyEmail(code);
        const translationKey = 'project-module.subscription.email-verification';
        Notify.create({
            message: t(`${translationKey}.${ successful ? 'successful' : 'unsuccessful' }`),
            position: 'top',
            timeout: 5000,
            type: successful ? 'positive' : 'negative',
        });
        if (successful) subscriberCount.value++;
    }
});

// STORE
const userStore = useUserStore();

// CONST
const userSubscribed = ref<boolean>(false);
const emailAlreadyInList = ref<boolean>(false);
const emailNeedsVerification = ref<boolean>(false);
const subscriberCount = ref<number>(0);
const emailInputRef = ref();

// PROPS
const props = defineProps<{
    module: Module;
}>();

const moduleConfig = props.module.config as ProjectModuleSubscriptionConfigDto;
userSubscribed.value = moduleConfig.isUserSubscribed;
subscriberCount.value = moduleConfig.subscriberCount;

// COMPUTED
const userLoggedIn = computed(() => {
    return userStore.isUserLogged;
});

// FORM VALIDATION
const schema = yup.object({
    email: yup
        .string()
        .when([], {
            is: () => userLoggedIn.value,
            then: (schema) => schema.notRequired(),
            otherwise: (schema) => schema.required().email().test((value) => /\.[a-zA-Z0-9]{2,}$/.test(value)),
        }),
});


const { handleSubmit, setFieldValue } = useForm({
    validationSchema: schema,
    initialValues: { email: null },
});

const { value: emailField, errorMessage } = useField<string | null>("email");


// FUNCTIONS
const subscribe = handleSubmit(async (values) => {
    const subscriptionStatus = await mailingListService.subscribe(
        constant.MailingList.Parent.project,
        props.module.project_id,
        values.email
    );
    resetForm();
    switch (subscriptionStatus) {
        case constant.MailingList.SubscriptionStatus.AlreadySubscribed:
            emailAlreadyInList.value = true;
            break;
        case constant.MailingList.SubscriptionStatus.NeedEmailVerification:
            emailNeedsVerification.value = true;
            break;
        case constant.MailingList.SubscriptionStatus.NewlySubscribed:
            userSubscribed.value = true;
            subscriberCount.value++;
            break;
    }
});

async function unsubscribe() {
    const isUnsubscribed = await mailingListService.unsubscribe(
        constant.MailingList.Parent.project,
        props.module.project_id,
    )
    if (isUnsubscribed) {
        subscriberCount.value--;
        resetForm();
    }
}

function updateEmailInput(email: string) {
    if (email) {
        emailNeedsVerification.value = false;
        emailAlreadyInList.value = false;
    }
    emailField.value = email;
}

function resetForm() {
    userSubscribed.value = false;
    emailNeedsVerification.value = false;
    emailAlreadyInList.value = false;
    emailField.value = null;
    setFieldValue('email', null);
    emailInputRef.value?.clearValue();
}
</script>
<template>
    <div class="subscription-module">
        <div class="subscription-module__title">
            <q-icon name="o_email"/>
            <h3>{{ getTranslation(props.module, 'title')}}</h3> 
        </div>
        <div class="subscription-module__content">
            <div class="subscription-module__content-text col">
                <span v-if="!userSubscribed">{{ t('project-module.subscription.status.not-subscribed') }}</span>
                <span v-else>{{ t('project-module.subscription.status.already-subscribed') }}</span>
                <span class="count">
                    {{ t('project-module.subscription.subscriber-count', { subscriberCount }, subscriberCount)}}
                </span>
            </div>
            <div class="subscription-module__content-actions">
                <div class="buttons">
                    <BaseInput  v-if="!userLoggedIn"
                                :model="emailField"
                                @update="updateEmailInput"
                                type="email"
                                :placeholder="t('project-module.subscription.email-placeholder')"
                                name="email"
                                ref="emailInputRef"/>
                    <BaseButton v-if="!userSubscribed"
                                :text="t('project-module.subscription.button.subscribe')"
                                @click="subscribe"
                                primary
                                :disabled="!userLoggedIn && (!!errorMessage || emailField === null)"/>
                    <BaseButton v-if="userSubscribed && userLoggedIn"
                                :text="t('project-module.subscription.button.unsubscribe')"
                                @click="unsubscribe"
                                primary/>
                </div>
                <div class="status">
                    <span v-if="emailAlreadyInList">
                        {{ t('project-module.subscription.status.already-on-email-list')}}
                    </span>
                    <span v-else-if="emailNeedsVerification">
                        {{ t('project-module.subscription.status.need-email-verification')}}
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.subscription-module {
    display: flex;
    flex-direction: column;
    background-color: #cedbe4;
    border-radius: 8px;
    gap: $space-sm;
    padding: $space-lg;

    &__title {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: $space-sm;

        .q-icon {
            font-size: $medium-title;
        }
    }

    &__content {
        display: flex;
        flex-direction: row;
        gap: $space-sm;
        width: 100%;
        align-items: flex-start;

        &-text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 50%;

            .count {
                color: rgba(0, 0, 0, 0.5);
            }
        }

        &-actions {
            display: flex;
            flex-direction: column;
            gap: $space-sm;
            width: 50%;

            .buttons {
                display: flex;
                flex-direction: row;
                justify-content: right;
                width: 100%;
                gap: $space-md;

                button, .base-inputs {
                    width: 50%;
                    height: 44px;
                }
            }
        }
    }

    .q-field {
        padding-bottom: 0;
    }
}

@media screen and (max-width: $breakpoint-md) {
    .subscription-module {

        &__content {
            flex-direction: column;

            &-actions {
                width: inherit;

                .buttons {
                    flex-direction: column;

                    .q-field, .q-btn {
                        width: 100%;
                    }
                }
            }

            &-text {
                width: inherit;
            }
        }
    }
}
</style>
