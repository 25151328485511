import { LanguageTranslation } from "./language.interface";

export interface DTOTimelineStep {
    id: number;
    project_module_id: number;
    title: LanguageTranslation;
    content: LanguageTranslation;
    status: number;
    order_num: number;
}

export interface TimelineStep {
    id: number;
    project_module_id: number;
    title: LanguageTranslation;
    content: LanguageTranslation;
    status: number;
    order_num: number;
}

export function parseTimelineStep(dto: DTOTimelineStep): TimelineStep {
    return {
        id: dto.id,
        project_module_id: dto.project_module_id,
        title: dto.title,
        content: dto.content,
        status: dto.status,
        order_num: dto.order_num
    };
}