<script setup lang="ts">

// PROPS
const props = defineProps<{
  color: string;
  icon: string;
  variant?: "filled" | "outlined";
}>();
</script>
<template>
  <div
    class="icon-container"
    :class="{ outlined: variant === 'outlined' }"
    :style="{ '--color': color }"
  >
    <q-icon :name="props.icon" :color="props.color"/>
  </div>
</template>
<style>
.icon-container {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: bold;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.icon-container.outlined {
  border: 2px solid var(--color);
  background-color: transparent;
  color: var(--color);
  padding: 4px; 
}


.icon-container:not(.outlined) {
  background-color: var(--color);
  color: white;
  box-shadow: 0 0 0 2px var(--color), inset 0 0 0 2px white;
}

</style>