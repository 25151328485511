<script setup lang="ts">

import { computed } from "vue";
import { useI18n } from "vue-i18n";

import { getTranslation } from "@/utils/data-display-utils";
import { LanguageTranslation } from '@/store/language.interface';
import { constant } from "@/constant";

import CircleIcon from "./CircleIcon.vue";

const { t } = useI18n();

// PROPS
const props = defineProps<{
  title: LanguageTranslation;
  content: LanguageTranslation;
  status: number;
  isFeaturedItem?: boolean;
}>();


// COMPUTED
const iconMap = (item: number) =>
  computed(() => {
    switch (item) {
    case constant.ProjectModule.TimelineStep.Status.Completed:
      return { color: "#8D9F89", icon: "check" };
    case constant.ProjectModule.TimelineStep.Status.Current:
      return { color: "#007DBA", icon: "access_time" };
    case constant.ProjectModule.TimelineStep.Status.Upcoming:
      return { color: "#4EA8D4", icon: "event" };
    }
  }
);

</script>
<template>
    <div class="timeline-item" :class="{ completed: props.status === constant.ProjectModule.TimelineStep.Status.Completed }">
      <div class="timeline-item__top">
        <div class="timeline-item__top-title">
          {{ getTranslation(props, 'title') }}
        </div>
        <div class="timeline-item__top-content" :class="{ featured : props.isFeaturedItem }" v-html="getTranslation(props, 'content')"></div>
      </div>
      <div class="timeline-item__bottom">
        <q-separator/>
        <div class="timeline-item__bottom-status">
          <circle-icon :color="iconMap(props.status).value.color" 
                       :icon="iconMap(props.status).value.icon"
                       :variant="props.status === constant.ProjectModule.TimelineStep.Status.Current ? 'filled' : 'outlined'"/>
          <div v-if="props.status === constant.ProjectModule.TimelineStep.Status.Completed">
            {{t('project-module.timeline.status.completed')}}
          </div>
          <div v-if="props.status === constant.ProjectModule.TimelineStep.Status.Current">
            {{t('project-module.timeline.status.current')}}
          </div>  
          <div v-if="props.status === constant.ProjectModule.TimelineStep.Status.Upcoming">
            {{t('project-module.timeline.status.upcoming')}}
          </div>        
        </div>
      </div>
    </div>
</template>
<style lang="scss">
.timeline-item {
  background-color: $color-neutral-white;
  border-radius: 8px;
  padding: $space-lg;
  display: flex;
  flex-direction: column;
  gap: $space-md;
  justify-content: space-between;
  height: 100%;

  &:hover {
    box-shadow: 0 6px 10px 0 rgba($color-neutral-grey-400-50, 0.2);
    transition: all 200ms ease;
  }

  &__top {
    display: flex;
    justify-content: start;
    flex-direction: column;
    gap: $space-sm; 

    &-title {
      display: flex;
      justify-content: center;
      font-size: $small-title;
      font-weight: bold;
    }

    &-content {
      display: block;
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 5;
      line-clamp: 5;
      -webkit-box-orient: vertical;
      max-height: calc(1.5em * 7); 
      text-overflow: ellipsis;

      &.featured {
        -webkit-line-clamp: 7;
        line-clamp: 5;
      }
    }
  }

  &__bottom {
    &-status {
      display: flex;
      justify-content: center;
      padding: $space-md 0 0 0;
      align-items: center;
      gap: $space-sm;
    }
  }

  &.completed {
    background-color: #E1E7EC;
  }
}

@media screen and (max-width: $breakpoint-sm) {
  .timeline-item__top-content {
    -webkit-line-clamp: 7;
    line-clamp: 7;

    &.featured {
      -webkit-line-clamp: 12;
      line-clamp: 12;
    }
  }
}

@media screen and (max-width: $breakpoint-lg) {
  .timeline-item__top-content {
    -webkit-line-clamp: 5;
    line-clamp: 5;

    &.featured {
      -webkit-line-clamp: 14;
      line-clamp: 14;
    }
  }
}
</style>