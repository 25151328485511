import { Config } from '@/config';
import apiService from "@/services/api.service";
import axios, { CancelTokenSource } from 'axios';

const CancelToken = axios.CancelToken;
let cancelTokenPlatforms: CancelTokenSource | null = null;

class MailingListService {
  async createVerificationCode(email: string, locale: string, parent: number, parent_id: number) {
    if (cancelTokenPlatforms) {
      cancelTokenPlatforms.cancel('Multiple requests.');
    }
    cancelTokenPlatforms = CancelToken.source();

    try {
      const requestData = { email, locale, parent, parent_id };
      const response = await apiService.post(`${Config.api.backend.urlV2}/mailing-lists/create-verification-code`, requestData);
      return response.data;
    } catch (error) {
      console.error(error)
    }

  }


  async checkVerificationCode(email: string, code: string): Promise<boolean> {
if (cancelTokenPlatforms) {
      cancelTokenPlatforms.cancel('Multiple requests.');
    }
    cancelTokenPlatforms = CancelToken.source();

    try {
      const requestData = {
            email, code
        };
      const response = await apiService.post(`${Config.api.backend.urlV2}/mailing-lists/check-verification-code`, requestData);
      return response.data;
    } catch (error) {
      console.error(error)
    }
  }

  async subscribeOld(parent: string, parent_id: number, user_id: number): Promise<boolean> {

    if (cancelTokenPlatforms) {
      cancelTokenPlatforms.cancel('Multiple requests.');
    }
    cancelTokenPlatforms = CancelToken.source();

    try {
      const requestData = {
            userId: user_id
        };
      const response = await apiService.post(`${Config.api.backend.urlV2}/mailing-lists/${parent}/${parent_id}/subscribe`, requestData);
      return response.data;
    } catch (error) {
      console.error(error)
    }
  }

  async subscribe(parent: number, parentId: number, email?: string): Promise<number> {
    if (cancelTokenPlatforms) {
      cancelTokenPlatforms.cancel('Multiple requests.');
    }
    cancelTokenPlatforms = CancelToken.source();
    try {
      const requestData = { parent, parentId, email };
      const response = await apiService.post(`${Config.api.backend.urlV2}/mailing-lists/:subscribe`, requestData);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  async unsubscribe(parent: number, parentId: number): Promise<boolean> {
    if (cancelTokenPlatforms) {
      cancelTokenPlatforms.cancel('Multiple requests.');
    }
    cancelTokenPlatforms = CancelToken.source();
    try {
      const requestData = { parent, parentId };
      const response = await apiService.post(`${Config.api.backend.urlV2}/mailing-lists/:unsubscribe`, requestData);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }

  async verifyEmail(code: string): Promise<boolean> {
    if (cancelTokenPlatforms) {
      cancelTokenPlatforms.cancel('Multiple requests.');
    }
    cancelTokenPlatforms = CancelToken.source();
    try {
      const requestData = { code };
      const response = await apiService.post(`${Config.api.backend.urlV2}/mailing-lists/:verify-email`, requestData);
      return response.data;
    } catch (error) {
      console.error(error);
    }
  }
}

export const mailingListService = new MailingListService();
