import { StoredFile } from "./common.interface";
import { LanguageTranslation } from "./language.interface";

export interface DTOModule {
    id?: number;
    title: LanguageTranslation;
    project_id: number;
    module_type: number;
    config: ProjectModuleConfigDto; 
}

export interface Module {
    id?: number;
    title: LanguageTranslation;
    project_id: number;
    module_type: number;
    config: ProjectModuleConfigDto; 
}

export interface ProjectModuleConfigDto {
    highlight: boolean;
}

export interface ProjectModuleTextConfigDTO extends ProjectModuleConfigDto {
    content: LanguageTranslation;
}

export interface ProjectModuleImageTextConfigDto extends ProjectModuleTextConfigDTO {
    image: StoredFile;
    imageAlignment: number;
}

export interface ProjectModuleVideoConfigDto extends ProjectModuleTextConfigDTO {
    videoType: string;
    video: LanguageTranslation;
    videoAlignment: number;
}

export interface ProjectModuleConsultationConfigDto extends ProjectModuleConfigDto {
    consultationId: number;
}

export interface ProjectModuleSubscriptionConfigDto extends ProjectModuleConfigDto {
    isUserSubscribed: boolean;
    subscriberCount: number;
}

export function parseModule(dto: DTOModule): Module  {
    return {
        id: dto.id,
        title: dto.title,
        project_id: dto.project_id,
        module_type: dto.module_type,
        config: dto.config 
    } as Module;
}