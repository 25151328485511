<script setup lang="ts">

import { computed, onMounted, ref } from "vue";
import { QExpansionItem, QList } from 'quasar';

import { getTranslation } from "@/utils/data-display-utils";
import { Module } from '@/store/module.interface';
import { Project } from "@/store/project.interface";
import { useCommunityStore } from "@/store/communityStore";
import { frequentlyAskedQuestionService } from "@/services/frequentlyAskedQuestions.service";
import { FrequentlyAskedQuestion } from "@/store/faq.interface";


// CONST
const questions = ref<FrequentlyAskedQuestion[]>();

// STORE
const communityStore = useCommunityStore();


// PROPS
const props = defineProps<{
    module: Module;
}>();


// COMPUTED
const project = computed<Project>(() => {
    return communityStore.community.projects.find(project => project.id == props.module.project_id);
})


// LIFECYCLE
onMounted(async () => {
    questions.value = await frequentlyAskedQuestionService.getModuleFaqs(project.value.id, props.module.id);
})


</script>

<template>
    <div class="faq-module">
        <div class="faq-module__title">
            <h3>{{ getTranslation(props.module, 'title') }}</h3>
        </div>
        <div class="faq-module__content" v-for="question in questions" :key="question.id">
            <q-list>
                <q-expansion-item :group="props.module.id.toString()">
                    <template v-slot:header>
                        <q-item-section>
                            {{ getTranslation(question, 'question') }}
                        </q-item-section>
                    </template>
                    <template v-slot>
                        <q-card>
                            <q-card-section>
                                <div v-html="getTranslation(question, 'answer')"></div>
                            </q-card-section>
                        </q-card>
                    </template>
                </q-expansion-item>
            </q-list>
        </div>
    </div>
</template>

<style lang="scss">
.faq-module {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: $space-md;

    &__title {
        margin: $space-md 0;
    }

    &__content {
        &:not(:last-child) {
            margin-bottom: 8px;
        }

        .q-item__section {
            font-size: $small-title;
        }

        .q-item {
            padding: 16px;
            height: 100% !important;
            border-radius: 8px;

            &__section {
                .q-icon {
                    color: $color-primary;
                }
            }
        }

        .q-hoverable:hover {
            background-color: #CEDBE480;
            color: inherit;
            border-radius: 8px;
            &> .q-focus-helper {
                opacity: 0 !important;
            }
        }

        .q-expansion-item--expanded, .q-expansion-item__content > .q-card{
            background-color: #CEDBE4 !important;
            border-radius: 8px;
            font-size: $body-text;
        }
    }
}
</style>
