<script setup lang="ts">
import { onMounted, onUnmounted, watch } from "vue";

// PROPS
const props = defineProps<{
    gtmId: string,
    gtmEnabled: boolean,
    cookiesEnabled: boolean
}>();

const scriptId = "gtm-script";
const noScriptId = "gtm-noscript";

// FUNCTIONS
const injectGtmScript = () => {
    if (document.getElementById(scriptId)) return; // Prevent duplicate injection

    const script = document.createElement("script");
    script.id = scriptId;
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtm.js?id=${props.gtmId}`;
    document.head.appendChild(script);

    const noscript = document.createElement("noscript");
    noscript.id = noScriptId;
    noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${props.gtmId}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
    document.body.appendChild(noscript);

    // Set default consent state (blocking all tracking until consent is given)  
    (window as any).dataLayer.push({  
        event: 'default_consent',  
            'defaultAdsConsent': 'denied',  
            'defaultAnalyticsConsent': 'denied',  
    }); 
};

const removeGtmScript = () => {
    document.getElementById(scriptId)?.remove();
    document.getElementById(noScriptId)?.remove();
};

const pushCookiesEnabled = () => {
    (window as any).dataLayer.push({
        event: 'update_consent',
        'analytics_storage': 'granted',
        'ads_storage': 'granted',
    });
};

// LIFECYCLE
onMounted(() => {
    if (props.gtmEnabled) {
        injectGtmScript();
    } else if(props.gtmEnabled && props.cookiesEnabled) {
        injectGtmScript();
        pushCookiesEnabled();
    }
});

onUnmounted(() => {
  removeGtmScript();
});

// WATCH 
watch(() => props.cookiesEnabled, (newValue) => {
        if(newValue) {
            pushCookiesEnabled();
        }
    }
);
</script>

<template>
  <div></div> <!-- This component doesn't render anything -->
</template>
