<script setup lang="ts">

import { ref, onMounted, computed } from "vue";
import { getTranslation } from "@/utils/data-display-utils";
import useResponsive from "@/utils/responsive-util";

import TimelineItem from "../TimelineItem.vue";
import TimelineItemModal from "../TimelineItemModal.vue";

import { timelineStepsService } from "@/services/timelineSteps.service";
import { Module } from '@/store/module.interface';
import { useCommunityStore } from "@/store/communityStore";
import { Project } from "@/store/project.interface";
import { constant } from "@/constant";
import { DTOTimelineStep } from "@/store/timelineStep.interface";


// STORE
const communityStore = useCommunityStore();

const { isMobile } = useResponsive();

// CONST
const timelineSteps = ref<DTOTimelineStep[]>([]);
const currentTimelineItem = ref<number>();
const timelineitemDialogRef = ref(null);
const openedItem = ref();


// PROPS
const props = defineProps<{
    module: Module;
}>();


// LIFECYCLE
onMounted(async () => {
    timelineSteps.value = await timelineStepsService.getModuleTimelineSteps(project.value.id, props.module.id);
    currentTimelineItem.value = timelineSteps.value.find(
        item => item.status === constant.ProjectModule.TimelineStep.Status.Current
    )?.order_num || timelineSteps.value[0]?.order_num || null;
})


// COMPUTED
const project = computed<Project>(() => {
    return communityStore.community.projects.find(project => project.id == props.module.project_id);
})

const previousStep = computed<DTOTimelineStep>(() => {
    if (currentTimelineItem.value === 1) {
        return null;
    }
    return timelineSteps.value.find((item, index) => index === currentTimelineItemIndex.value - 1) || null;
});

const nextStep = computed<DTOTimelineStep>(() => {
    return timelineSteps.value.find((item, index) => index === currentTimelineItemIndex.value + 1) || null;
})

const currentTimelineItemIndex = computed<number>(() => {
    return timelineSteps.value.findIndex(item => item.order_num === currentTimelineItem.value);
})

// FUNCTIONS
function openTimelineItemModal(value) {
    if (value === 'previous'){
        openedItem.value = {
            title: previousStep.value.title,
            content: previousStep.value.content,
            status: previousStep.value.status
        };
    } else if (value === 'next') {
        openedItem.value = {
            title: nextStep.value.title,
            content: nextStep.value.content,
            status: nextStep.value.status
        };
    } else {
        openedItem.value = timelineSteps.value.find(item => item.order_num === currentTimelineItem.value);
    }
    timelineitemDialogRef.value.openDialog();
}
</script>
<template>
    <div class="module-timeline">
        <div class="module-timeline__module-title">
            <h3>{{ getTranslation(props.module, 'title') }}</h3>
        </div>
        <div class="module-timeline__timeline" >
            <div v-if="timelineSteps && previousStep != null && !isMobile" class="previous">
                <timeline-item :title="previousStep.title" 
                               :content="previousStep.content"
                               :status="previousStep.status"
                               @click="openTimelineItemModal('previous')"/>
            </div>
            <q-carousel
                v-model="currentTimelineItem"
                swipeable
                animated
                control-color="primary"
                padding
                arrows
                class="rounded-borders"
            >
                <q-carousel-slide  v-for="item in timelineSteps" :name=item.order_num>
                    <timeline-item :title="item.title" 
                               :content="item.content"
                               :status="item.status"
                               is-featured-item
                               @click="openTimelineItemModal('current')"/>
                </q-carousel-slide>
            </q-carousel>
            <div v-if="timelineSteps && nextStep != null && !isMobile" class="next">
                <timeline-item :title="nextStep.title" 
                               :content="nextStep.content"
                               :status="nextStep.status"
                               @click="openTimelineItemModal('next')"/>
            </div>
        </div>
        <timeline-item-modal :item="openedItem" ref="timelineitemDialogRef"/>
    </div>
</template>
<style lang="scss">
.module-timeline {
    display: flex;
    flex-direction: column;
    gap: $space-sm;

    &__timeline {
        height: 400px;
        position: relative;
        margin: 0 0 $space-lg 0 ;
        cursor: pointer;

        .previous {
            position: absolute;
            left: 10%;
            top: 50%;
            transform: translate(-10%, -50%);
            width: 25%;
            height: 80%;
            z-index: 1;
        }

        .next {
            position: absolute;
            right: 10%;
            top: 50%;
            transform: translate(10%, -50%);
            width: 25%;
            height: 80%;
            z-index: 1;
        }
    }

    .q-carousel__slide {
        padding: 0 !important;
        border-radius: 8px;
        width: 30% !important;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .q-carousel {
        height: 400px !important;
    }
}

@media screen and (max-width: $breakpoint-sm) {
    .module-timeline {
        .q-carousel__slide {
            width: 70% !important;
        }

        .q-carousel .q-panel {
            background-color: transparent !important;
        }
    }
    
}

@media screen and (max-width: $breakpoint-md) {
    .module-timeline {
        .q-carousel__slide {
            width: 70% !important;
        }

        .q-carousel .q-panel {
            background-color: transparent !important;
        }
    }
    
}
</style>