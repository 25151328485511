import { Config } from '@/config';
import apiService from "@/services/api.service";

import { useCommunityStore } from '@/store/communityStore';
import { DTOTimelineStep } from '@/store/timelineStep.interface';


class TimelineStepsService {
    async getModuleTimelineSteps(projectId: number, moduleId: number): Promise<DTOTimelineStep[]> {
        const response = await apiService.get(
          `${Config.api.backend.urlV2}/project-modules/${moduleId}/timeline-steps`,
        );
        if (response.data) {
          const communityStore = useCommunityStore();
          communityStore.setProjectModuleTimelineSteps(response.data, moduleId, projectId);
        }
        return response.data;
    };
}

export const timelineStepsService = new TimelineStepsService();
