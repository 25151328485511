<script setup lang="ts">

// PROPS
const props = defineProps<{
    source: string,
    color: string,
    altText: string
}>();

</script>
<template>
    <div class="logo">
        <img :src="props.source" :alt="props.altText" />
    </div>
</template>
<style lang="scss">
.logo {
    background-color: v-bind(color);
    display: flex;
    padding: $space-sm;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: inherit; //to be set by parent
    width: inherit; //to be set by parent

    img {
        max-width: 100%;
        max-height: 100%;
    }
}
</style>