import { Quasar } from 'quasar';
import { createI18n } from "vue-i18n";
import messages from "@intlify/unplugin-vue-i18n/messages";
import { Localize } from "@/utils/i18n/locale";
import langFr from 'quasar/lang/fr'
import langEn from 'quasar/lang/en-US'

const quasarLangMap = {
    fr: langFr,
    en: langEn
}

// Create Vue I18n instance.
export const i18n = createI18n({
	legacy: false,
	globalInjection: true,
	locale: "fr",
	fallbackLocale: "fr",
	messages: messages,
});

// Set new locale.
export function setLocale(locale: Localize) {
	i18n.global.locale.value = locale;
	Quasar.lang.set(quasarLangMap[locale]);
    document.documentElement.lang = locale;
}

export function currentLocale() {
	return i18n.global.locale.value;
}

export function useCurrentLocale() {
	return i18n.global.locale;
}

export function getLocales() {
	return i18n.global.availableLocales;
}