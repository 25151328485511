<script setup lang="ts">
import { computed, inject, onMounted, ref, watch, provide } from "vue";
import { storeToRefs } from "pinia";
import { setLocale } from "@/plugins/i18n";
import { RouterView } from "vue-router";

import Header from "@/components/layout/Header.vue";
import Footer from "@/components/layout/Footer.vue";
import ConsentEmailDialog from "@/components/dialogs/ConsentEmailDialog.vue";
import CookieConsent from "@/components/dialogs/CookieConsent.vue";
import PageLoading from "@/views/PageLoading.vue";
import QuebecLaw96Banner from "@/components/layout/QuebecLaw96Banner.vue";

import { setPrimaryColors } from "@/utils/style";
import useResponsive from "@/utils/responsive-util";
import { getTranslation } from "@/utils/data-display-utils";
import { useUserStore } from "@/store/userStore";
import { useCommunityStore } from '@/store/communityStore';
import { VueCookies } from "vue-cookies";
import { communitiesService } from "@/services/communities.service";
import { consultationsService } from "@/services/consultations.service";
import { customPageService } from "@/services/customPage.service";
import { authService } from "@/services/auth.service";
import GTMScript from "./components/GTMScript.vue";

const cookies = inject<VueCookies>('$cookies')

// STORE
const userStore = useUserStore();
const { isUserLogged } = storeToRefs(userStore)
const communityStore = useCommunityStore();
const { isLoaded, community } = storeToRefs(communityStore)
const { isMobile } = useResponsive()
const consentDialogRef = ref(null);
const cookieConsentDialogRef = ref(null);
const results = ref(false);
provide('results', results)

onMounted(async () => {
    if (cookies.get('locale')) {
        setLocale(cookies.get('locale'));
    } else {
        setLocale('fr');
    }
    setPrimaryColors();
    if (!communityStore.isLoaded) {
        const communitiesData: any = await communitiesService.getCommunities();
        await authService.initUser();
        if (communitiesData) {
            communityStore.setCommunity(communitiesData);
            if (communitiesData.favicon_file) {
                const favicon = document.getElementById("favicon")!;
                favicon.href = communitiesData.favicon_file.url;
            }
            if (communitiesData.language_enabled.length === 1) {
                setLocale(communitiesData.language_enabled[0])
            }
            await customPageService.getCustomPages();
            await consultationsService.getConsultations();

            if (!consentCookieValid.value) {
                cookieConsentDialogRef.value.openDialog();
            }

        }
        communityStore.isLoaded = true;
    }
});

// COMPUTED
const consentCookieValid = computed(() => {
    const consentCookie = cookies.get('consent');
    return (consentCookie !== undefined && consentCookie !== null)
})

const consentCookieEnabled = computed(() => {
    const consentCookie = cookies.get('consent');
    return (consentCookie !== undefined && consentCookie !== null && consentCookie !== 0);
})

const consentEmailAuthorization = computed(() => userStore.needGiveConsentForEmail)
const shouldShowTermsDialog = computed(() => userStore.isTermsApprovalRequired)


// FUNCTIONS 
function updateResults(value) {
    results.value = value;
}


// WATCH
watch(isUserLogged, (newValue, oldValue) => {
        if (shouldShowTermsDialog.value) {
            consentDialogRef.value.openDialog();
        }
    }
);
</script>

<template>
    <div class="base-layout">
        <div class="base-layout__container" v-if="isLoaded">
            <Header @results="updateResults($event)"/>
            <QuebecLaw96Banner :community="community"/>
            <div class="base-layout__page-content">
                <RouterView/>
            </div>
            <Footer v-if="!isMobile"/>
        </div>
        <div class="base-layout__container" v-else>
            <page-loading/>
        </div>
    </div>
    <consent-email-dialog
        ref="consentDialogRef"
        :community-title="isLoaded && getTranslation(community, 'title')"
        :organization-name="isLoaded && getTranslation(community, 'organizationName')"
    />
    <cookie-consent ref="cookieConsentDialogRef"/>
    <GTMScript :gtm-id="communityStore.getCommunityGtmId" 
               :gtm-enabled="communityStore.isCommunityGtmEnabled" 
               :cookies-enabled="consentCookieEnabled"/>
</template>

<style lang="scss">
.base-layout {
    display: flex;
    min-height: 100vh;
    width: 100%;
    position: relative;

    &__container {
        display: flex;
        flex: auto;
        flex-direction: column;
        width: 100%;
    }

    &__page-content {
        height: 100%;
    }
}
.q-notification__actions i {
    color: #fff;
}
</style>
