// MUST BE SYNC WITH FILE ON API /api/src/constant/index.js

export const constant = {
    FormComments: {
        Status: {
            Deleted: 0,
            Active: 1
        },
        ModerationStatus: {
            Refused: 0,
            Approved: 1,
            Pending: 2
        },
        Action: {
            like: 1,
            flag: 2,
            dislike: 3
        }
    },
    Form: {
        Type: {
            Proposal: 1,
            Question: 2,
            OpenQuestion: 3,
            Level: 4,
            Map: 5
        },
        Status: {
            Deleted: 0,
            Approved: 1,
            Pending: 2
        }
    },
    Community: {
        Status: {
            Deleted: 0,
            Active: 1,
            PreLaunch: 2
        },
        CustomPage: {
            About: 1,
            Content: 2,
            Redirect: 3,
        }
    },
    Consultation: {
        Status: {
            PreLaunch: 0,
            Ongoing: 1,
            Completed: 2,
            Archived: 3
        },
        Type: {
            Public: 1,
            Private: 2
        }
    },
    User: {
        Role: {
            SUPERADMIN: 0,
            ADMIN: 1,
            EDITOR: 2,
            MODERATOR: 3,
            VIEWER: 4
        }
    },
    Context: {
        Parent: {
            consultation: 1,
            section: 2,
            form: 3
        },
        Type: {
            Text: 1,
            Image: 2,
            Video: 3,
            File: 5,
            Link: 6,
        }
    },
    HomePageItem: {
        Type: {
            Consultation: 1,
            Project: 2,
        }
    },
    MailingList: {
        Parent: {
            community: 1,
            consultation: 2,
            project: 3
        },
        SubscriptionStatus: {
            NewlySubscribed: 1,
            AlreadySubscribed: 2,
            NeedEmailVerification: 3,
        },
    },
    Project: {
        Status: {
            PreLaunch: 0,
            Ongoing: 1,
            Completed: 2,
            Archived: 3
        },
        Type: {
            Public: 1,
            Private: 2
        }
    },
    ProjectModule: {
        Type: {
            Text: 1,
            Video: 2,
            Consultation: 3,
            Attachments: 4,
            FrequentlyAskedQuestions: 5,
            Subscription: 6,
            Timeline: 7
        },
        Alignment: {
            Left: 1,
            Right: 2,
            Center: 3
        },
        TimelineStep: {
            Status: {
                Upcoming: 1,
                Current: 2,
                Completed: 3,
            },
        }
    },
    Pagination: {
        DefaultRowsPerPage: 10,
    },
};