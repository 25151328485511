import { defineStore } from "pinia";
import { upLoadService } from "@/services/upload.service";
// @ts-ignore
import { mockedGenders, mockedLanguages } from "@/mockedData.js";
import {useI18n} from "vue-i18n";
import { constant } from "@/constant";

export interface UserDto {
    id: number | null,
    email: string | null,
    language_code: string | null,
    email_validated: number | null,
    first_name: string | null,
    last_name: string | null,
    year_birth: string | null,
    gender: string | null,
    postal_code: string | null,
    emailConsent: number | null,
    isCommunityMailingListMember: number | null,
    terms_approval: number,
    role: number,
    consultationIds: number[] | null,
    projectIds: number[] | null,
}

export interface User {
    id: number | null;
    firstName: string | null,
    lastName: string | null,
    postalCode: string | null,
    preferredLanguage: string | null,
    birthYear: string | null,
    gender: string | null,
    email: string | null,
    uniqueId: UniqueId | null,
    emailConsent: number | null,
    hasApprovedTerms: boolean,
    isCommunityMailingListMember: number | null,
    role: number,
    consultationIds: number[] | null,
    projectIds: number[] | null,
}

export interface Avatar {
    cropped: string | null,
    original: string | null
}

interface UniqueId {
    type: string,
    value: string
}

interface Gender {
    id: number,
    name: string
}

interface Language {
    id: number,
    name: string,
    value: string
}

export const useUserStore = defineStore("userStore", {
    state: () =>
        ({
            user: {} as User,
            genders: [] as Gender[],
            languages: [] as Language[],
            profileCompletion: 0 as number,
            accessToken: localStorage.getItem("accessToken") || undefined as string | undefined,
        }),
    getters: {
        getUserName(): string | void {
            if (this.user && this.user.id) {
                const { firstName, lastName, email } = this.user;
                if (firstName && lastName) {
                    return `${firstName} ${lastName}`;
                } else if (firstName) {
                    return firstName;
                } else if (lastName) {
                    return lastName;
                } else {
                    return getUniqueId(this.user)
                }
            }
        },
        isUserLogged(): boolean {
            return this.user.id !== undefined && this.user.id !== null;
        },
        getUserId(): string | null {
            return this.user.id;
        },
        validateDemographicData(): boolean {
            //todo: valider avec yup...
            return (this.user.postalCode !== '' && this.user.gender !== '' && this.user.birthYear !== '' && this.user.first_name !== '' && this.user.last_name !== '')
        },
        isTermsApprovalRequired(): boolean {
            return (this.user.id !== undefined && this.user.id !== null) && !this.user.hasApprovedTerms;
        },
        validateUserName(): boolean {
            return this.userFirst_name !== '' && this.user.lastName !== ''
        }
    },
    actions: {
        setUser(dto: UserDto | null) {
            if (!dto) {
                this.resetUser();
                return;
            }

            this.user = parseUser(dto);
        },
        loadGenders() {
            const { t } = useI18n();
            this.genders =  mockedGenders.map(gender => {
                return {
                    value: gender.id,
                    label: t(gender.name)
                }
            })
        },
        loadLanguages() {
            const { t } = useI18n();
            this.languages =  mockedLanguages.map(lang => {
                return {
                    value: lang.value,
                    label: t(lang.name)
                }
            })
        },
        resetUser() {
            return this.user = {
                id: null,
                firstName: "",
                lastName: "",
                postalCode: "",
                preferredLanguage: "",
                birthYear: "",
                gender: "",
                email: "",
                uniqueId: {
                    type: "",
                    value: ""
                },
                emailConsent: null,
                isCommunityMailingListMember: null,
                hasApprovedTerms: true,
                role: constant.User.Role.VIEWER,
                consultationIds: null,
            }
        },
        async setUserAvatar(image: Avatar) {
            try {
                if (this.user) {
                    const { cropped, original } = await upLoadService.postProfileImage(image)
                    this.user.avatar.original = original
                    this.user.avatar.cropped = cropped
                }
            } catch (error) {
                console.error(error);
            }
        },
        removeAvatar() {
            if (this.user) {
                this.user.avatar = getDefaultAvatar()
            }
        },
        setConsentEmail(consent: boolean) {
            this.user.emailConsent = consent;
        },
        setTermsApproval(approval: boolean) {
            this.user.hasApprovedTerms = approval;
        },
        setCommunityMailingListMember(subscription: boolean) {
            this.user.isCommunityMailingListMember = subscription;
        },
        isAdmin(): boolean {
            return this.user.role === constant.User.Role.ADMIN;
        },
        isEditor(homepageItemType: number | null = null, homepageItemId: number | null = null): boolean {
            const items = (() => {
                switch (homepageItemType) {
                    case constant.HomePageItem.Type.Consultation:
                        return this.user.consultationIds;
                    case constant.HomePageItem.Type.Project:
                        return this.user.projectIds;
                    default:
                        return null;
                }
            })();
            return this.user.role === constant.User.Role.EDITOR && (!homepageItemId || items?.includes(homepageItemId));
        },
    }
});

function getDefaultAvatar() {
    return {
        cropped: './src/assets/images/blank_avatar.jpeg',
        original: './src/assets/images/blank_avatar.jpeg',
    }
}

function parseUser(dto: UserDto): User {
    return {
        id: dto.id,
        firstName: dto.first_name ?? '',
        lastName: dto.last_name ?? '',
        postalCode: dto.postal_code ?? '',
        preferredLanguage: dto.language_code ?? '',
        birthYear: dto.year_birth ?? '',
        gender: dto.gender ?? '',
        email: dto.email,
        uniqueId: { type: 'email', value: dto.email },
        emailConsent: dto.emailConsent,
        hasApprovedTerms: dto.terms_approval === 1,
        isCommunityMailingListMember: dto.isCommunityMailingListMember,
        role: dto.role,
        consultationIds: dto.consultationIds,
        projectIds: dto.projectIds,
    }
}

export function getUniqueId(user: User | undefined): string {
    if (!user || !user.uniqueId) {
        return '';
    }
    if (user.uniqueId.type === 'email') {
        return maskEmail(user.uniqueId.value);
    } else if (user.uniqueId.type === 'phoneNumber') {
        return maskPhoneNUmber(user.uniqueId.value);
    } else {
        return 'Social ID';
    }
}


export function maskEmail(email: string | undefined): string {
    if (!email) {
        return '';
    }
    const [username, domain] = email.split('@');
    const maskedUsername = username.charAt(0) + '*'.repeat(username.length - 2) + username.charAt(username.length - 1);
    const maskedDomain = domain.charAt(0) + '*'.repeat(domain.indexOf('.', 1) - 2) + domain.slice(domain.indexOf('.', 1) - 1);
    return `${maskedUsername}@${maskedDomain}`;
}

function maskPhoneNUmber(phoneNumber: string) {
    const replaced = phoneNumber.split('');
    const positionsToReplace = [1, 2, 5, 9, 10];

    for (let i = 0; i < positionsToReplace.length; i++) {
        const position = positionsToReplace[i];
        replaced[position] = '*';
    }

    return replaced.join('');
}