<script setup lang="ts">

import { ref } from 'vue';
import BaseDialog from './base/BaseDialog.vue';
import TimelineItem from './TimelineItem.vue';
import { DTOTimelineStep } from "@/store/timelineStep.interface";

// CONST
const timelineitemDialogRef = ref<InstanceType<typeof BaseDialog>>();

// PROPS
const props = defineProps<{
  item: DTOTimelineStep
}>();

// FUNCTIONS
function openDialog() {
	timelineitemDialogRef.value?.openDialog();
}

function closeDialog() {
	timelineitemDialogRef.value?.closeDialog();
}

defineExpose({
	openDialog,
	closeDialog,
});

</script>
<template>
    <BaseDialog ref="timelineitemDialogRef" @close="closeDialog()" :have-close-button="false">
        <template v-slot:content>
            <timeline-item :title="props.item.title" 
                            :content="props.item.content"
                            :status="props.item.status" />
        </template>
    </BaseDialog>
</template>